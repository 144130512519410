<template>
<div class="setup">
    <van-cell class="setupitem" title="我的目标" is-link  @click="goTarget()" v-if="userInfo && userInfo.is_join == 1" />
    <van-cell class="setupitem" title="我的兴趣" is-link  @click="goInterest()" v-if="userInfo && userInfo.is_join == 1" />
    <van-cell class="setupitem" title="收货地址" is-link  @click="goAddress()" />
    <van-cell class="setupitem" title="关于我们" is-link  @click="goAbout()" />
    <div class="log-out-con">
        <div class="log-out" @click="logOut()">退出登录</div>
    </div>
    <van-toast id="van-toast" />
</div>

</template>

<script>
export default {
  name: "user.setup",
  data(){
    return{

    }
  },

    created() {

  },
  methods:{
      goTarget(){
        this.$router.push('/user/target')  
      },
      goInterest(){
          this.$router.push('/user/interest')
      },
      goAddress(){
            this.$router.push('/user/address')
      },
      goAbout(){
           this.$router.push('/user/about')
      },
      logOut(){
           this.$toast('退出成功');
          this.$cache.removeLoginInfo();
          setTimeout(()=>{
            window.location.href= window.location.origin
          },1500)
      }
  },
  components:{
  },
   computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/user/setup/setup.scss";
.setupitem{
  width: 90%;
  margin: 0 5%;
  padding: 20/$r 0;
  border-bottom: 1px solid #F0F2F4;
  box-sizing: border-box;
}
</style>
<style lang="scss">
.setup .van-cell:after{
      border-bottom: unset;
}
</style>